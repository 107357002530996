export default {
  namespaced: true,
  state: {
    langs: [],
  },
  getters: {
    getLocales: state => {
      const { langs } = state
      langs.map(x => {
        x.locale = x.iso_code
      })
      return langs
    },
    getLangs: state => {
      const { langs } = state
      return langs
    },
  },
  mutations: {
    setLangs(state, data) {
      state.langs = data
    },
  },
  actions: {},
}