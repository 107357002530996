import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import IdleVue from "idle-vue";

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// FONTAWESOME
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEdit, faUserSecret, faLink, faUnlock, faUnlink, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
library.add(faEdit, faUserSecret, faLink, faUnlock, faUnlink, faTrash, faTrashAlt, faTrashCan)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Idle
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 9000000, // 900000 = 15 min en milisegundos
  startAtIdle: false
});

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

/* 
GLOBAL DATA / METHODS
A Mixin is an object that contains the same options 
as a component and can be reused and distributed 
between Vue components.
*/
Vue.mixin({
  data() {
    return {}
  },
  methods: {
    isEducamadrid() {
      console.log(process.env.VUE_APP_URL)
      
      if (process.env.VUE_APP_URL.includes('dev.services')) return true
      if (process.env.VUE_APP_URL.includes('educa.madrid.org')) return true
      if (process.env.VUE_APP_URL == "https://raicessmileandlearn.educa.madrid.org/") return true
      if (process.env.VUE_APP_URL == "https://raicessmileandlearn.educa.madrid.org") return true
      return false
    },
    resolveUserRoleVariant(role) {
      if (role) {
        role = role.toLowerCase()
      }
      if (role === 'partner') return 'warning'
      if (role === 'admin') return 'danger'
      if (role === 'user') return 'success'
      return 'primary'
    },
    resolveUserRoleIcon(role) {
      if (role) {
        role = role.toLowerCase()
      }
      if (role === 'user') return 'UserIcon'
      if (role === 'partner') return 'BriefcaseIcon'
      if (role === 'admin') return 'ServerIcon'
      return 'SmileIcon'
    },
    resolveUserStatusVariant(status) {
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    },
  },
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

/**
 * Uncomment below when compiling to production
 
 Vue.config.devtools = false
 Vue.config.debug = false
 Vue.config.silent = true
 
 */

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
