export default [
    /* {
        path: '/content-manager',
        name: 'content-manager',
        component: () => import('@/views/content-manager/ContentManager.vue'),
    }, */
    {
        path: '/content-manager/platforms/smile-and-learn/versions',
        name: 'sl-versions',
        component: () => import('@/views/content-manager/platforms/Versions.vue'),
    },
    {
        path: '/content-manager/platforms/smile-and-learn/customizations',
        name: 'sl-customizations',
        component: () => import('@/views/content-manager/platforms/Customizations.vue'),
    },
    {
        path: '/content-manager/platforms/smile-and-learn/plugins',
        name: 'sl-plugins',
        component: () => import('@/views/content-manager/platforms/slPlugins.vue'),
    },
    {
        path: '/content-manager/platforms/smile-and-learn/plugins',
        name: 'bb-plugins',
        component: () => import('@/views/content-manager/platforms/bbPlugins.vue'),
    },
    {
        path: '/content-manager/inteligences',
        name: 'inteligences',
        component: () => import('@/views/content-manager/ContentManager.vue'),
    },
    {
        path: '/content-manager/categories',
        name: 'categories',
        component: () => import('@/views/content-manager/ContentManager.vue'),
    },
    {
        path: '/content-manager/subcategories',
        name: 'subcategories',
        component: () => import('@/views/content-manager/ContentManager.vue'),
    },
    {
        path: '/content-manager/contents',
        name: 'contents',
        component: () => import('@/views/content-manager/ContentManager.vue'),
    },
]
/* 
content-manager
    platforms
        SmileAndLearn
            versions -> tabla
            customization -> tabla
            plugins ?
        BreakingBarriers
            plugins 
                - Manage Speech Plugin
    inteligencias
    categorias
    subcategorias
    contenidos */