import Vue from 'vue'

// axios
import axios from 'axios'

let localURL = process.env.VUE_APP_URL;
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: localURL + '/tebackend/v1/',
  // timeout: 1000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns
