export default [
    {
      path: '/',
      name: 'main-home',
      component: () => import('@/views/dashboard/home/MainHome.vue'),
      meta: {
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/account-setting',
      name: 'account-setting',
      component: () => import('@/views/user/account-setting/AccountSetting.vue'),
      meta: {
        resource: 'Account Settings',
        action: 'read',
      },
    },
    {
      path: '/user-home',
      name: 'user-home',
      component: () => import('@/views/worlds/Dash.vue'),
      meta: {
        resource: 'Web', //'WebPlatform',
        action: 'read',
      },
    },
    {
      path: '/learning-paths',
      name: 'learning-paths',
      component: () => import('@/views/learning-paths/LearningPaths.vue'),
      meta: {
        resource: 'Learning Paths',
        action: 'read',
      },
    },
    {
      path: '/groups',
      name: 'groups',
      component: () => import('@/views/groups/Groups.vue'),
      meta: {
        resource: 'Groups',
        action: 'read',
      },
    },
    {
      path: '/dreamers',
      name: 'dreamers',
      component: () => import('@/views/dreamers/Dreamers.vue'),
      meta: {
        resource: 'Dreamers',
        action: 'read',
      },
    },
    {
      path: '/dreamers/:id',
      name: 'dreamer-view',
      component: () => import('@/views/dreamers/DreamerView.vue'),
      meta: {
        resource: 'Dreamers',
        action: 'read',
      },
    },
    {
      path: '/reports/create',
      name: 'reports-create',
      component: () => import('@/views/reports/CreateReport.vue'),
      meta: {
        resource: 'Reports',
        action: 'create',
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/reports/ReportsList.vue'),
      meta: {
        resource: 'Reports',
        action: 'read',
      },
    },
    {
      path: '/resources',
      name: 'resources',
      component: () => import('@/views/resources/Resources.vue'),
      meta: {
        resource: 'Educational Resources',
        action: 'read',
      },
    },
    {
      path: '/resources/tutorials',
      name: 'tutorials',
      component: () => import('@/views/resources/Tutorials.vue'),
      meta: {
        resource: 'Tutorials',
        action: 'read',
      },
    },
    {
      path: '/resources/guides',
      name: 'guides',
      component: () => import('@/views/resources/Guides.vue'),
      meta: {
        resource: 'Guides',
        action: 'read',
      },
    },
    {
      path: '/resources/catalogs',
      name: 'catalogs',
      component: () => import('@/views/resources/Catalogs.vue'),
      meta: {
        resource: 'Catalogs',
        action: 'read',
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/help/Help.vue'),
      meta: {
        resource: 'Help',
        action: 'read',
      },
    },
    {
      path: '/faq/:category/:id/:slug',
      name: 'faq-question',
      component: () => import('@/views/help/FaqQuestion.vue'),
      meta: {
        resource: 'Help',
        action: 'read',
      },
    },
    {
      path: '/school-structure',
      name: 'school-structure',
      component: () => import('@/views/schools/Structure.vue'),
      meta: {
        resource: 'School Structure',
        action: 'read',
      },
    },
  ]