import initialAbility from '@/libs/acl/config.js'
export default {
  namespaced: true,
  state: {
    accessToken:"",
    ability: initialAbility,
    avatar: "",
    email: "",
    extras: "",
    fullName: "",
    id: null,
    lang: "",
    role: "",
    username: "",
    school: null,
  },
  getters: {
    getRole: state => {
      const { role } = state
      if (role) return role
      return 'user'
    },
    getLang: state => {
      const { lang } = state
      if (lang) return lang
      return 'Español'
    },
    isSchool: state=>{
        return state.school
    },
    getUserData: state => {
      let userData = {
        fullName: state.fullName,
        username: state.username,
        role: state.role,
        avatar: state.avatar,
      }
      return userData
    },
    getToken: state => {
      return state.accessToken
    }

  },
  mutations: {
    setToken(state, data){
      state.accessToken = data
    },
    setUser(state, data) {
      state.ability = data.userData.ability
      state.avatar = data.userData.avatar
      state.email = data.userData.email
      state.extras = data.userData.extras
      state.fullName = data.userData.fullName
      state.id = data.userData.id
      state.lang = data.userData.lang
      state.role = data.userData.role
      state.username = data.userData.username
      state.school = data.userData.school
      console.log("store mutation setUser", state)
    },
    logout(state, data) {
      state.accessToken = ""
      state.ability = []
      state.avatar = ""
      state.email = ""
      state.extras = ""
      state.fullName = ""
      state.id = null
      state.lang = ""
      state.role = ""
      state.username = ""
      state.school = null
      console.log("store mutation logout", state)
    },
    changeLang(state, value){
      state.lang = value
    }
  },
  actions: {},
}